import { S3 } from "@aws-sdk/client-s3";


export const endpoint = 'https://nyc3.digitaloceanspaces.com'


export const s3client = new S3({
    endpoint,
    region: 'us-east-1',
    credentials: {
        accessKeyId: process.env.REACT_APP_ACCESS_KEY_DIGITAL_OCEAN,
        secretAccessKey: process.env.REACT_APP_SECRET_KEY_DIGITAL_OCEAN,
    }
})