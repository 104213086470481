import React, { useState } from 'react';
import './ImageUploader.scss';

const ImageUploader = ({ setImage }) => {
  const [preview, setPreview] = useState(null);
  const [imageName, setImageName] = useState("");
  const [imageSize, setImageSize] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // Estado para el mensaje de error

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const maxSizeInKB = 5120; // Tamaño máximo en KB (5 MB)

    if (file) {
      // Validar tipo de archivo
      if (!file.type.startsWith('image/')) {
        setErrorMessage("Por favor, seleccione un archivo de imagen válido.");
        return;
      }

      // Validar tamaño del archivo
      if (file.size / 1024 > maxSizeInKB) {
        setErrorMessage(`El archivo es demasiado grande. El tamaño máximo es de ${maxSizeInKB / 1024} MB.`);
        return;
      }

      // Limpiar el mensaje de error si las validaciones son exitosas
      setErrorMessage("");

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);

      setImage(file);
      setImageName(file.name);
      setImageSize((file.size / 1024).toFixed(2) + " KB");
    }
  };

  return (
    <div className="image-uploader">
      <div className="upload-btn-wrapper">
        <input type="file" accept="image/*" id="file-upload" onChange={handleImageChange} />
        <label htmlFor="file-upload" className="custom-file-upload">
          Subir Imagen
        </label>
      </div>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      {preview && (
        <div className="image-preview-container">
          <div className="image-preview">
            <img src={preview} alt="Image Preview" />
          </div>
          <div className="image-info">
            <p className="image-name">{imageName}</p>
            <p className="image-size">{imageSize}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageUploader;
